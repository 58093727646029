<template>
  <v-container class="container-card">
    <FiltroRelatorios @selectedFilters="filters = $event" />
    <Relatorios :filters="filters" :list="menus" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import FiltroRelatorios from "@/components/comissao/relatorios/FiltroRelatorios";
import Relatorios from "@/components/comissao/relatorios/Relatorios";

export default {
  components: {
    FiltroRelatorios,
    Relatorios
  },

  data() {
    return {
      filters: {},
      menus: [
        {
          title: "Relatório Veículos",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório Curto",
              service: "veiculoDetalhadoCurto",
              permission: 981
            },
            {
              text: "Relatório Extenso",
              service: "veiculoDetalhado",
              permission: 1001
            },
            {
              text: "Relatório Devolução",
              service: "veiculoDevolucao",
              permission: 991
            },
            // {
            //   text: "Relatório Supervisor Equipe",
            //   service: "detalhadoSupervisorEquipe",
            //   permission: 990
            // },
            {
              text: "Relatório Entrega",
              service: "veiculoEntrega",
              permission: 992
            }
          ]
        },
        {
          title: "Relatórios Consórcio",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório Consórcio",
              service: "detalhadoConsorcio",
              permission: 987
            },
            {
              text: "Relatório Bônus",
              service: "detalhadoBonus",
              permission: 941
            },
            {
              text: "Relatório Bônus Vendedor",
              service: "bonusVendedor",
              permission: 988
            }
            // {
            //   text: "Relatório Consórcio - P2",
            //   service: ""
            // }
          ]
        },
        {
          title: "Relatórios Resumo",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório CDC/CNH/NOVOS/USADOS/ENTREGA",
              service: "resumoVendedor",
              permission: 1004
            },
            {
              text: "Relatório Produto Força",
              service: "comissaoProdutoForca",
              permission: 985
            }
          ]
        }
      ]
    };
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Relatórios Depto Comercial");
  }
};
</script>

<style></style>
